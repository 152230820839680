import { useEffect, useRef, useState } from "react";
import {httpEdit, httpGet} from "../../Api/api";
import * as React from "react";
import {BooleanInput} from "react-admin";
import {EBooleanStatuses} from "../../Const/Enums";
import {useLocation} from "react-router-dom";

const CATEGORY_TYPE = 2;
const TRACK_YOUR_PROGRESS_TYPE = 3;
const STORAGE_ORDERS_KEY = 'orders'

export const HomeBlocksLocalization = ({localization}) => {
    const [orders, setOrders] = useState([])
    const [duplicates, setDuplicates] = useState([])
    const [duplicatesTypes, setDuplicatesTypes] = useState([])
    const [selectedValue, setSelectedValue] = useState('')
    const [validate, setValidate] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (!location.pathname.includes(localization)) {
            return;
        }
        getList()
    }, [location])

    useEffect(() => {
        if (typeof window === 'undefined') {
            setValidate(false)

            return;
        }

        const getItemsWithNonUniqueOrder = (data) => {
            const orderCount = {};
            data.forEach(item => {
                orderCount[item.order] = (orderCount[item.order] || 0) + 1;
            });

            return data.filter(item => orderCount[item.order] > 1);
        };

        setDuplicates(getItemsWithNonUniqueOrder(orders.filter((item) => item.type !== CATEGORY_TYPE && item.type !== TRACK_YOUR_PROGRESS_TYPE)))

        const notUniquesList = (ordersList) => {
            const list = ordersList.filter((item) => item.type !== CATEGORY_TYPE && item.type !== TRACK_YOUR_PROGRESS_TYPE);
            const typeCategory = ordersList.find((item) => item.type === CATEGORY_TYPE)
            const typeTrackYourProgress = ordersList.find((item) => item.type === TRACK_YOUR_PROGRESS_TYPE)
            const notUniqueCategory = list.filter((item) => item.order === typeCategory.order)
            const notUniqueTrackYourProgress = list.filter((item) => item.order === typeTrackYourProgress.order)

            const notUniqueListCategory = notUniqueCategory.length ? [...notUniqueCategory, typeCategory] : [];
            const notUniqueListTrackYourProgress = notUniqueTrackYourProgress.length ? [...notUniqueTrackYourProgress, typeTrackYourProgress] : [];

            setDuplicatesTypes([...notUniqueListCategory, ...notUniqueListTrackYourProgress])
        }

        notUniquesList(orders)

        if (localStorage.getItem(STORAGE_ORDERS_KEY)) {
            const initialOrders = JSON.parse(localStorage.getItem(STORAGE_ORDERS_KEY) || [])
            if (JSON.stringify(orders) ===  JSON.stringify(initialOrders)) {
                setValidate(true)

                return;
            }
        }

        const hasEmptyOrder = orders.some(orderItem => orderItem.order === '');
        if (hasEmptyOrder) {
            setValidate(true);
            return;
        }

        setValidate(false);
    }, [location, orders, selectedValue])

    const getList = () => {
        httpGet(`home-blocks/edit/${localization}`).then(res => {
            if (res &&
                res.data &&
                res.data.items &&
                res.data.items.length) {
                setOrders([...res.data.items])
                if (typeof window !== 'undefined') {
                    localStorage.setItem(STORAGE_ORDERS_KEY, JSON.stringify(res.data.items))
                }
            } else {
                setOrders([])
                if (typeof window !== 'undefined') {
                    localStorage.setItem(STORAGE_ORDERS_KEY, JSON.stringify([]))
                }
            }
        });
    }

    const changeOrder = (e, id) => {
        const value = parseInt(e.target.value);
        if (value < 0 || value > 99) return

        if (isNaN(value) || value < 0 || value > 99 || e.target.value.length > 2) {
            e.target.value = '';
        }

        setSelectedValue(e.target.value)

        setOrders((prev) => {
            const changedIndex = prev.findIndex((order) => order.id === id)

            if (changedIndex > -1) {
                if (value === 0) {
                    prev[changedIndex].order = value
                } else {
                    prev[changedIndex].order = value || ''
                }

                return [...prev]
            }

            return [...prev]
        })
    }

    const changeTitle = (e, id) => {
        const value = e.target.value;

        setOrders((prev) => {
            const changedIndex = prev.findIndex((order) => order.id === id)

            if (changedIndex > -1) {
                prev[changedIndex].title = value

                return [...prev]
            }

            return [...prev]
        })
    }

    const setPublished = (value, id) => {
        setOrders((prev) => {
            const changedIndex = prev.findIndex((order) => order.id === id)

            if (changedIndex > -1) {
                prev[changedIndex].published = value ? 1 : 0

                return [...prev]
            }

            return [...prev]
        })
    }

    const editCancel = () => {
        setDuplicates([])
        setDuplicatesTypes([])
        if (typeof window !== 'undefined') {
            const initialOrders = JSON.parse(localStorage.getItem(STORAGE_ORDERS_KEY) || [])
            setOrders(initialOrders)
        }
    }

    const editCreate = () => {
        if (orders.some((item) => duplicates.find((duplicate) => duplicate.id === item.id))) {

            return
        }

        if (orders.some((item) => duplicatesTypes.find((duplicate) => duplicate.id === item.id))) {

            return
        }

        if (orders.length && !validate) {
            const payload = orders.map((orderItem) => {
                return {
                    id: orderItem.id,
                    title: orderItem.title,
                    order: orderItem.order,
                    published: orderItem.published,
                }
            })

            httpEdit('home-blocks', { items: payload }).then(() => getList())
        }
    }

    return <>
        { orders && orders.length ? orders.map((item) => {
            return <div key={item.id} className="home-blocks__item">
                <p className="booleanInputDescription">{item.name}</p>
                <input name="title" placeholder="Name" className="title-input" value={item.title}
                       onChange={(e) => changeTitle(e, item.id)}/>
                <input type="number" value={item.order} pattern="[0-9]{1,2}"
                       className={`${!item.order.toString() || duplicates.find((duplicate) => duplicate.id === item.id) || duplicatesTypes.find((duplicate) => duplicate.id === item.id) ? 'error' : ''}`}
                       onKeyDown={(e) => {
                           if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                               e.preventDefault();
                           }
                       }} onChange={(e) => {
                    changeOrder(e, item.id)
                }} required/>
                <div className="orders-form">
                    <BooleanInput label={item.published ? 'Active' : 'Inactive'} name="published"
                                  source="form.published" className="booleanInput"
                                  defaultValue={item.published ? EBooleanStatuses.True : EBooleanStatuses.False}
                                  checked={item.published ? EBooleanStatuses.True : EBooleanStatuses.False}
                                  onChange={(e) => {
                                      setPublished(e.target.checked, item.id)
                                  }}/>
                </div>
                {item.group ? <p className="booleanInputDescription last">Group: {item.group}</p> : null}
            </div>
        }) : null}
        <div className="dailyActionToolbar">
            <button
                onClick={editCancel}
                className="button bar-purple-button"
                type="button"
            >
                <span>Cancel</span>
            </button>
            <button
                disabled={validate || duplicates.length}
                onClick={editCreate}
                className="button bar-gradient-button"
                type="button"
            >
                <span>Save</span>
            </button>
        </div>
    </>
}
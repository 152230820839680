const regex = /^(100|[1-9][0-9]?)$/;
export const hasDuplicateSelectedCategory = (categoriesList) => {
    const seen = new Set();

    return categoriesList.some(cat => {
        const value = cat.selectedCategory?.value;

        if (seen.has(value)) {
            return true;
        }

        seen.add(value);
        return false;
    });
};

export const validateCategory = (value) => {
    return !regex.test(value)
}

export const validateCategories = (categories) => {
    return categories.length && categories.some((cat) => validateCategory(cat.priority)) || hasDuplicateSelectedCategory(categories)
}
import {
    Edit,
    required,
    TextInput,
    Link,
    FunctionField,
    SimpleForm,
    TabbedShowLayout,
    Tab, useNotify, useDataProvider, useRefresh, SelectInput
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {useParams} from "react-router-dom";
import GroupLocalization from "./groupLocalization";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";
import {validateCategories} from "./utils/GroupCategories";
import GroupCategoriesComponent from "./GroupCategories/GroupCategoriesComponent";

export const EditGroup = () => {
    const {id} = useParams();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const [categoriesList, setCategoriesList] = useState([])

    const [formData] = useState({
        name: '',
        description: '',
    });

    const editCreate = () => {
        if (validateCategories(categoriesList)) return

        if (!formData.name.length || formData.order <= 0) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }
            if (formData.order <= 0) {
                document.querySelector('.order').classList.add('error');
            }

            return;
        }
        const categories = categoriesList.map((cat) => {
            return {
                id: cat.selectedCategory.value,
                order: cat.priority
            }
        })
        dispatch(setLoadStatus(true));
        dataProvider.update(`groups/general`, {id: `${id}`, data: {
                name: formData.name,
                description: formData.description,
                categories
            }}).then(() => {
            dispatch(setLoadStatus(false));
            refresh(true);
            notify(`Group update`);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                <Link to="/groups/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit Group</h2>
            <Edit title="Group">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <FunctionField label="" render={(record) => {
                                formData.name = record.name;
                                return <>
                                    <TextInput className="formFields name" label="Name" source="name" fullWidth
                                               id="name" validate={required()} onChange={(e) => {
                                        nameChange(e)
                                    }} multiline/>
                                    <p className="required-text">Required</p>
                                </>;
                            }}/>
                            <FunctionField label="" render={(record) => {
                                formData.description = record.description;
                                return <TextInput className="formFields" label="Description" name="description"
                                                  source="description" id="description" multiline fullWidth
                                                  onChange={(e) => {
                                                      formData.description = e.target.value
                                                  }}/>;
                            }}/>
                            <GroupCategoriesComponent onChangeCategories={(categories) => setCategoriesList(categories)} />
                            <Save/>
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <GroupLocalization/>
                    </Tab>
                    <Tab label="PT" path="pt">
                        <GroupLocalization/>
                    </Tab>
                    <Tab label="DE" path="de">
                        <GroupLocalization/>
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}
import AsyncSelect from "react-select/async";
import * as React from "react";
import {memo, useEffect, useRef, useState} from "react";
import {v4 as generateId} from "uuid";
import {httpGet} from "../../../../Api/api";
import {validateCategory} from "../utils/GroupCategories";
import {useParams} from "react-router-dom";

const GroupCategoriesComponent = ({ onChangeCategories }) => {
    const {id} = useParams();
    const initialRef = useRef(true);

    const [categoriesChoices, setCategoriesChoices] = useState([]);
    const [newCategories, setNewCategories] = useState([]);

    useEffect(() => {
        onChangeCategories(newCategories)
    }, [newCategories])

    useEffect(() => {
        httpGet('groups/general/category-list').then(res => {
            if (res.data.length) {
                setCategoriesChoices(res.data);
            }
        });
    }, []);

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        if (!id) return;

        Promise.all([
            httpGet('groups/general/category-list'),
            httpGet(`groups/general/${id}`)
        ]).then(([list, editData]) => {
            let choicesList = []
            if (list.data.length) {
                choicesList = list.data
                setCategoriesChoices(choicesList);
            }
            if (editData?.data?.categories?.length) {
                editData.data.categories.sort((a, b) => Number(a.order) - Number(b.order)).forEach((cat) => {
                    setNewCategories((prev) => [
                        ...prev,
                        {
                            choices: choicesList,
                            id: generateId(),
                            selectedCategory: {
                                id: generateId(),
                                label: cat.name,
                                value: cat.id,
                            },
                            priority: cat.order.toString(),
                            categoryDuplicateError: false,
                            priorityError: false
                        }]);
                })
            }
        })
    }, [id])

    const loadOptions = () => {
        return new Promise((resolve, reject) => {
            return httpGet('groups/general/category-list').then((res) => {
                if (res.data.length) {
                    resolve(res.data)
                } else {
                    resolve([])
                }
            }, reject)

        })
    };
    const addCategorySelect = () => {
        setNewCategories((prev) => [
            ...prev,
            {
                choices: categoriesChoices,
                id: generateId(),
                selectedCategory: {},
                priority: '100',
                categoryDuplicateError: false,
                priorityError: false
            }]);
    };

    const setDuplicatesErrors = (categoriesList) => {
        const map = new Map();

        categoriesList.forEach(item => {
            const value = item.selectedCategory.value;
            map.set(value, (map.get(value) || 0) + 1);
        });

        return categoriesList.map(item => {
            if (item.selectedCategory.value && map.get(item.selectedCategory.value) > 1) {
                return {...item, categoryDuplicateError: true};
            }
            return {...item, categoryDuplicateError: false};
        })
    }

    const changeCategory = (e, category) => {
        const data = {
            id: category.id,
            label: category.choices.find((el) => el.value === e.value)?.label,
            value: e.value
        }

        setNewCategories((prev) => {
            const existingCategoryIndex = prev.findIndex((item) => item.id === data.id)

            if (existingCategoryIndex >= 0) {
                prev[existingCategoryIndex].selectedCategory = data
                return [...setDuplicatesErrors(prev)]
            }

            return [...setDuplicatesErrors(prev)]
        })
    }

    const changeCategoryPriority = (e, category) => {
        setNewCategories((prev) => {
            const existingCategoryIndex = prev.findIndex((item) => item.id === category.id)
            if (existingCategoryIndex >= 0) {
                prev[existingCategoryIndex].priorityError = Boolean(isNaN(+e.target.value) || validateCategory(e.target.value))
                prev[existingCategoryIndex].priority = e.target.value

            }
            return [...prev]
        })
    }

    const deleteCategory = (category) => {
        setNewCategories((prev) => {
            const data = prev.filter((cat) => cat.id !== category.id)

            if (data.length) {
                return [...setDuplicatesErrors(data)]
            }

            return [...data]
        });
    }

    return <div className="group-categories">
        <div className="group-categories__item">
            <div className="group-categories__item-select">
                <div className="group-categories__item-select-titles">
                    <p className="group-categories__item-title">Categories</p>
                    <p className="group-categories__item-title">Priority</p>
                </div>
                {newCategories?.length ? newCategories.map((category, i) => {
                    return (
                        <div key={category.id} className="group-categories__item-select-container">
                            <div className="group-categories__item-select-wrap">
                                <AsyncSelect
                                    placeholder="Select Category"
                                    className="async-select"
                                    source="categories"
                                    defaultValue={category.selectedCategory}
                                    defaultOptions={category.choices}
                                    loadOptions={loadOptions}
                                    onChange={(e) => changeCategory(e, category)}
                                />
                                <button type="button" className="group-categories__item-select-delete"
                                        onClick={() => deleteCategory(category)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10 11.1666L5.91671 15.25C5.76393 15.4028 5.56949 15.4791 5.33337 15.4791C5.09726 15.4791 4.90282 15.4028 4.75004 15.25C4.59726 15.0972 4.52087 14.9028 4.52087 14.6666C4.52087 14.4305 4.59726 14.2361 4.75004 14.0833L8.83337 9.99998L4.75004 5.91665C4.59726 5.76387 4.52087 5.56942 4.52087 5.33331C4.52087 5.0972 4.59726 4.90276 4.75004 4.74998C4.90282 4.5972 5.09726 4.52081 5.33337 4.52081C5.56949 4.52081 5.76393 4.5972 5.91671 4.74998L10 8.83331L14.0834 4.74998C14.2362 4.5972 14.4306 4.52081 14.6667 4.52081C14.9028 4.52081 15.0973 4.5972 15.25 4.74998C15.4028 4.90276 15.4792 5.0972 15.4792 5.33331C15.4792 5.56942 15.4028 5.76387 15.25 5.91665L11.1667 9.99998L15.25 14.0833C15.4028 14.2361 15.4792 14.4305 15.4792 14.6666C15.4792 14.9028 15.4028 15.0972 15.25 15.25C15.0973 15.4028 14.9028 15.4791 14.6667 15.4791C14.4306 15.4791 14.2362 15.4028 14.0834 15.25L10 11.1666Z"
                                            fill="#EB5757"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="group-categories__item-select-priority">
                                <input
                                    type="number"
                                    defaultValue={category.priority}
                                    onChange={(e) => changeCategoryPriority(e, category)}
                                />
                            </div>
                            {category.categoryDuplicateError ?
                                <p className={'priority-error'}>Duplicate category {category.selectedCategory.label}!
                                    Please, choose another one</p> : null}
                            {!category.categoryDuplicateError && category.priorityError ?
                                <p className={'priority-error'}>Priority must be a number between 1 and
                                    100</p> : null}
                        </div>
                    );
                }) : null}
            </div>
        </div>
        {categoriesChoices?.length && newCategories?.length < categoriesChoices?.length ?
            <button
                type="button"
                className="button bar-purple-button m-0"
                onClick={() => addCategorySelect()}
            >
                Add Category
            </button>
            : null}
    </div>
}

export default memo(GroupCategoriesComponent)
import {
    Create,
    FormTab,
    Link,
    required,
    TabbedForm,
    TextInput,
    useCreate,
    useNotify,
    useRedirect
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";
import {validateCategories} from "./utils/GroupCategories";
import GroupCategoriesComponent from "./GroupCategories/GroupCategoriesComponent";

export const CreateGroup = () => {
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [description, setDescription] = useState([]);
    const [categoriesList, setCategoriesList] = useState([])

    const groupCreate = () => {
        if (validateCategories(categoriesList)) return
        dispatch(setLoadStatus(true));
        if (!name.length) {
            document.querySelector('.name').classList.add('error');
            dispatch(setLoadStatus(false));
            return;
        }

        const categories = categoriesList.map((cat) => {
            return {
                id: cat.selectedCategory.value,
                order: cat.priority
            }
        })

        create('groups/general', {
            data: {
                name,
                description,
                categories
            }
        }, {
            onSuccess: (res) => {
                dispatch(setLoadStatus(false));
                redirect(`/groups/general/${res.id}/en`);
                notify('Group created');
            },
            onError: (res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            }
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                <Link to="/groups/general" className="button bar-purple-button">
                    Cancel
                </Link>
                <button
                    onClick={groupCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    };

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        setName(e.target.value)
    };

    return (
        <>
            <h2 className="dailyTitle">New Group</h2>
            <Create title="Create Group">
                <TabbedForm className="tabbedForm" toolbar={<></>}>
                    <FormTab label="General">
                        <div className="tabbedFormWrap">
                            <TextInput
                                className="formFields name"
                                label="Name"
                                name="name"
                                source="name"
                                fullWidth
                                id="name"
                                validate={required()}
                                onChange={nameChange}
                                multiline
                            />
                            <p className="required-text">Required</p>
                            <TextInput
                                className="formFields"
                                label="Description"
                                name="description"
                                source="description"
                                id="description"
                                multiline
                                fullWidth
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                            <GroupCategoriesComponent onChangeCategories={(categories) => setCategoriesList(categories)} />
                        </div>
                        <Save />
                    </FormTab>
                    <FormTab label="EN" path="en" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="PT" path="pt" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="DE" path="de" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                </TabbedForm>
            </Create>
        </>
    );
};